// dependencies.
import React from 'react'
// components.
import HomepageLayout from 'src/templates/homepage'
import SimpleHead from 'src/components/head/SimpleHead'
// data.
import data from 'src/data/pages/Contents/home__es.json'

// Main component.
const IndexPage = () => (
  <HomepageLayout pageName="home__es" pageData={data} pathname="es" enableSubscribeNotice={false} />
)

export default IndexPage

// head and meta data.
export const Head = () => {
  const { title, description, keywords } = data

  return <SimpleHead title={title} description={description} keywords={keywords} />
}
